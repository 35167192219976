html,
{
    font-size: 0.9em;
    font-family: 'Roboto', sans-serif;
}

html,
body {
    height: 100%;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2.25rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.313rem;
}

h5 {
    font-size: 1.125rem;
}

h6 {
    font-size: 1rem;
}

p {
    margin: 0 0 10px;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

.row {
    margin: 0px;
}

.margin-clear {
    margin: 0;
}

.margin-auto {
    margin: 0 auto;
}

.margin-top-2 {
    margin-top: 2rem;
}

.margin-bottom-2 {
    margin-bottom: 2rem;
}

.margin-2 {
    margin: 2rem;
}

.margin-1 {
    margin: 1rem;
}

.padding-clear {
    padding: 0;
}

.padding-1 {
    padding: 1rem;
}

.padding-2 {
    padding: 2rem;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}